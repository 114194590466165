/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
// import VueNativeSock from 'vue-native-websocket'
import { getToken } from './token-util';
import { WEBSOCKTYPE } from  "@/enum/index"
import { Message } from "element-ui"
import router from '@/router/index'
import store from '@/store';
import Vue from 'vue'
/**
 * @description: 连接Socket
 * @param isRetry 是否是重试
 * @author: xuhao
 */
// 注册事件监听
Vue.prototype.$bus = new Vue();
// 几次收不到消息开始重连最多不超过四次
const tolerate = 2
// 当前心跳未响应次数
let pingCount = 0
// 当前重连次数
let retryCount = 0
// 最大允许重连次数
let maxRetryCount = 2
let pop = null
let heartbeatTimer = null, heartbeatTimer2 = null;
export function useSocket (isRetry = false){
    if(Vue.prototype.$socket && !isRetry && getToken()) {
        return
    }
    const heartbeatInterval = 5000
    let socket = new WebSocket(process.env.VUE_APP_API_BASE_WSS + '?token=' + getToken().replace('Bearer ', ''))
    Vue.prototype.$socket = socket
    socket.onclose = (e) => {
        stopHeartbeat()
        if(!retryCount) {
            stopHeartbeatLink()
        }
        Vue.prototype.$socket = null
        if(e.code !== 1005){
            reconnect()
        }
        
    }
    // 连接逻辑
    socket.onopen = () => {
        console.log('发送3')
        startHeartbeat()
        stopHeartbeatLink()
        if(pop) {
            if(router.history.current.name === 'examination') {
                Vue.prototype.$bus.$emit('networkFailure', true);
            }
            pop?.close()
            pop = null
        }
        pingCount = 0
        retryCount = 0
        
    };
    // 发消息逻辑
    socket.onmessage = (e) => {
        let data
        try {
            data = JSON.parse(e.data)
        } catch (e) {
            console.log(e)
            data = null
        }
        if(data.event === WEBSOCKTYPE.OTHERLOGIN){
            socket?.close()
            store.dispatch('loginOut')
            router.push({ name: 'login' })
            Message.warning("检查的您的账号在别的地方登录了,即将退出登录")
        }
        pingCount = 0
        retryCount = 0
        // 广播接收到的消息给其他组件
        
        Vue.prototype.$bus.$emit('broadcastMessage', data);
    }
    socket.onerror = () => {
        console.log('发送4')
        stopHeartbeat()
        // console.log('WebSocket连接错误:');
        // debugger
        // useSocket(true)
        // 连接错误会触发此回调函数，可以进行相应的处理，比如显示错误提示等
    };
    // 开始心跳检测
    function startHeartbeat() {
        stopHeartbeat()
        heartbeatTimer = setInterval(() => {
            console.log('发送1', pingCount)
            // 这里发送心跳数据给服务器，你可以根据服务器要求发送特定的数据
            pingCount++
            if(pingCount > tolerate) {
                stopHeartbeat()
                // 重连心跳
                reconnect()
            }
            socket.send('heartbeat');
        }, heartbeatInterval);
    }
    // 重连心跳
    function reconnect() {
        stopHeartbeatLink()
        heartbeatTimer2 = setInterval(() => {
            retryCount++
            if(retryCount > maxRetryCount && !pop) {
                pop = Vue.prototype.$loginOut()
                if(router.history.current.name === 'examination') {
                    // 广播接收到的消息给其他组件 false 网络状态不正确, true 网络状态恢复
                    Vue.prototype.$bus.$emit('networkFailure', false);
                } else {
                    pop.open()
                }
            }
            useSocket(true)
        }, heartbeatInterval);
    }
   // 停止心跳检测
    function stopHeartbeat() {
        if (heartbeatTimer) {
            clearInterval(heartbeatTimer);
            heartbeatTimer = null;
        }
    }
    // 停止连接心跳
    function stopHeartbeatLink() {
        if (heartbeatTimer2) {
            clearInterval(heartbeatTimer2);
            heartbeatTimer2 = null;
        }
    }
}