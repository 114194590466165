/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
/**
 * axios 实例
 */
import axios from 'axios';
// import router from '@/router';
import { Message } from 'element-ui';
import { API_BASE_URL, TOKEN_HEADER_NAME } from '@/config/setting';
import { getToken, setToken } from './token-util';
import router from "@/router/index"
import store from "@/store"
const service = axios.create({
  baseURL: API_BASE_URL
});

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
  (config) => {
    // 添加 token 到 header
    const token = getToken();
    if (token && config.headers) {
      config.headers.common[TOKEN_HEADER_NAME] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 */
service.interceptors.response.use(
  (res) => {
    // token 自动续期
    const token = res.headers[TOKEN_HEADER_NAME.toLowerCase()];
    if (token) {
      setToken(token);
    }
    return res;
  },
  (error) => {
     // 登录过期处理
     if (error.response?.data?.code === 401) {
      store.dispatch('loginOut')
      router.push({name: 'login'})
      location.reload()
    } if (error.response?.data?.code === 400) {
      Message.error(error.response.data.message)
      return error.response;
    }  else {
      Message.error(error.response.data.message)
      // return Promise.reject(error);
    }
    
  }
);

export default service;
