<!--
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { useSocket } from "@/utils/websocket"
import { mapGetters } from "vuex"
// import { getToken } from '@/utils/token-util';
export default {
  name: 'App',
  computed: {
    ...mapGetters(['globalStatus'])
  },
  watch: {
    'globalStatus.userInfo.id': {
        handler(newData) {
            if(newData){
                // 开启连接
                useSocket()
            } else {
                // 关闭连接
                this.$socket?.close()
            }
        },
        immediate: true,
        deep: true
    },
    'globalStatus.config.is_grey': {
      handler(newData) {
        if(newData){
          document.body.classList.add('setGray')
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
