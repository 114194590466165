/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import element from '@/elementUi/index'
import store from '@/store';
import LoginOut from "@/components/LoginOut"
import { errorLog } from "@/utils/utils"
import '@/style/public.less'
Vue.config.productionTip = false
Vue.prototype.$loginOut = LoginOut
Vue.use(element, { size: 'small', zIndex: 3000 })
// 监听全局的异常错误并记录
Vue.config.errorHandler = function (err, vm, trace) {
  errorLog(err.message, err.stack, 'errorHandler', trace)
  console.error('errorHandler：', err, vm, trace)
}
Vue.config.warnHandler = function (err, vm, trace) {
  errorLog(err, err, 'errorHandler', trace)
  // 警告处理逻辑
  console.error('Vue 警告：', err, vm, trace)
}
// window.addEventListener('error', function(event) {
//   const error = event.error
//   debugger
//   if (error instanceof DOMException) {
//     // 处理 DOM 异常错误逻辑
//     console.error('DOM 异常错误：', error)
//   }
// })
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
