/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */

import Vue from "vue";
import loginOut from "./index.vue";
// 1.用 Vue.extend 创建组件的模板（构造函数）
let messageConstructor = Vue.extend(loginOut);
let instance;
const LoginOut = function(options) {// options是传入的参数配置 {message: '成功',type: "success"offset: 80}
  // 2.实例化组件
  instance = new messageConstructor({ data: options }); // 把options导入data中
  // 3.组件挂载
  instance.$mount();
  document.body.appendChild(instance.$el);
  // 设置offset
  // let verticalOffset = options.offset || 20;
  // instance.verticalOffset = verticalOffset;
  // instance.duration = options.duration || 3000;
  return instance;
};
export default LoginOut;