/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
// token 传递的 header 名称
export const TOKEN_HEADER_NAME = 'Authorization';
// 外层布局的路由地址
export const LAYOUT_PATH = '/'; 
// 接口地址
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
// token 存储的名称
export const TOKEN_STORE_NAME = 'access_token';
// 千信云视频播放器huanj
export const QXYVOIDE_ENV = process.env.VUE_APP_API_PLAYENV
// 不检测网站维护的路由
export const NOTMAINTAINED = ['maintenance', 'homepageLive', 'noticeAnnouncement', 'noticeDetails', 'policy', 'policyDetails', 'journalism', 'journalismDetails', 'helpCenter', 'login', 'register', 'retrievePassword']
